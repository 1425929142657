.footer {
  background: #F8F8F8;
  padding: 60px 70px 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.footer__text {
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #a0a0a0;
  padding: 20px 0;
  border-bottom: 1px solid #e8e8e8;
}

.footer__copyright {
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__year {
  margin: 13px 0 20px;
}

.footer__links {
  padding-bottom: 5px;
}

.footer__link {
  text-decoration: none;
  color: #000;
  margin-left: 20px;
}

.footer__link:hover {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .footer {
    background: #FAFAFA;
    padding: 63px 30px 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  .footer__year {
    margin: 13px 0 20px;
    padding: 0;
  }

  .footer__links {
    padding-bottom: 7px;
  }
}

@media screen and (max-width: 460px) {
  .footer {
    padding: 30px 10px 0;
    font-size: 12px;
    line-height: 15px;
  }

  .footer__text {
    font-size: 12px;
    line-height: 15px;
  }

  .footer__copyright {
    margin-top: 30px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 18px;
  }
  
  .footer__year {
    margin: 5px 0 20px;
    padding: 0;
    letter-spacing: -0.04em;
  }

  .footer__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
  }

  .footer__link {
    margin-left: 0;
  }
}
