.profile {
  max-width: 410px;
  padding-top: 58px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.profile__welcome-message {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 108px;
  margin-left: -1px;
}

.profile-form__input-field:first-child {
  border-bottom: 1px solid #E8E8E8;
}

.profile-form__input-field {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
}

.profile-form__label {
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
}

.profile-form__input {
  border: none;
  background-color: #FAFAFA;
  padding: 0;
  text-align: right;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  outline: none;
}

.profile-form__input::placeholder {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}

.profile-form__input:disabled {
  background-color: #FAFAFA;
  color: #000;
}

.profile-form__buttons {
  position: relative;
}

.profile-form__button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  display: block;
  padding: 0;
  margin: 0 auto;
}

.profile-form__button-save {
  width: 100%;
  background: #3456F3;
  border-radius: 3px;
  border: 0;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  min-height: 45px;
  margin-top: 180px;
  cursor: pointer;
}

.profile-form__button-save:disabled {
  background: #f8f8f8;
  color: #c2c2c2;
}

.profile-form__button-edit {
  margin-top: 204px;
}

.profile-form__button-signout {
  font-weight: 500;
  color: #ee3465;
  margin-top: 16px;
}

.profile-form__input-field {
  position: relative;
}

.profile-form__input-error {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  position: absolute;
  max-width: 410px;
  margin: 16px 0 0;
  color: #ee3465;
}

.profile-form__input-error_active {
  color: #ee3465;
}

.profile-form__error-message {
  color: #ee3465;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  position: absolute;
  top: -35px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.profile-form__success-message {
  color: #3456F3;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  position: absolute;
  top: -35px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

@media screen and (max-width: 768px) {
  .profile {
    padding-top: 0;
    justify-content: center;
    margin: 0 auto;
    min-height: calc(100vh - 123px);
  }

  .profile__welcome-message {
    margin-bottom: 81px;
  }

  .profile-form__button-edit {
    margin-top: 194px;
  }
}

@media screen and (max-width: 460px) {
  .profile {
    min-height: auto;
    max-width: 260px;
    padding-top: calc(100vh - 93.1vh);
  }

  .profile-form__input-field {
    min-width: 260px;
  }

  .profile__welcome-message {
    margin-bottom: 65px;
  }

  .profile-form__button-edit {
    margin-top: calc(100vh - 53.6vh);
  }

  .profile-form__button {
    font-size: 12px;
    line-height: 15px;
  }
}
