.box__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
  z-index: 0;
}

.box__container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 520px;
  background-color: #FAFAFA;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1;
  display: grid;
  grid-template-rows: 53px;
}

.box__container.box__container_open {
  transform: translateX(0);
}

.box__burger-btn {
  all: unset;
  background: url('../../images/icon-burger.svg') no-repeat center;
  height: 44px;
  width: 44px;
  cursor: pointer;
}

.box__close-btn {
  all: unset;
  background: url('../../images/icon-close.svg') no-repeat center;
  height: 44px;
  width: 44px;
  cursor: pointer;
  justify-self: end;
  margin-top: 16px;
  margin-right: 16px;
}

.box__burger-btn:focus {
  outline: 2px solid black;
}

.box__close-btn:focus {
  outline: 2px solid black;
}

@media screen and (max-width: 768px) {
  .box__burger-btn {
    margin-top: 4px;
  }
}

@media screen and (max-width: 460px) {
  .box__burger-btn {
    background: url('../../images/icon-burger-mini.svg') no-repeat center;
    margin-right: 9px;
    height: 22px;
    width: 22px;
  }

  .box__container {
    width: 320px;
    grid-template-rows: 57px;
  }

  .box__close-btn {
    margin-top: 7px;
    margin-right: 7px;
  }
}
