@import url('./vendor/normalize.css');
@import url('./vendor/fonts/fonts.css');

html {
  scroll-behavior: smooth;
  background: #FAFAFA;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent;
}
