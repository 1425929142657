.about-me {
  padding: 112px 70px 0;
  background: #FAFAFA;
}

.about-me__container {
  display: flex;
  justify-content: space-between;
}

.about-me__section-title {
  margin: 0 0 23px;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.04em;
}

.about-me__description {
  padding-right: 20px;
}

.about-me__description-name {
  margin: 0;
  margin-top: 56px;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  letter-spacing: -0.04em;
}

.about-me__description-about {
  margin: 0;
  margin-top: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}

.about-me__description-text {
  margin: 23px 0 99px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  max-width: 595px;
}

.about-me__description-photo {
  width: 100%;
  max-width: 270px;
  height: auto;
  margin-top: 56px;
  max-height: 327px;
}

.about-me__link {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-decoration: none;
  transition: all 0.2s linear;
}

.about-me__link:hover {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .about-me {
    padding: 90px 50px 0;
  }

  .about-me__description-name {
    font-size: 40px;
    line-height: 40px;
  }

  .about-me__description-about {
    font-size: 12px;
    line-height: 18px;
    margin-top: 16px;
  }

  .about-me__description-text {
    margin: 19px 0 87px;
    font-size: 12px;
    line-height: 18px;
    max-width: 362px;
  }

  .about-me__description-photo {
    width: 100%;
    max-width: 255px;
    height: auto;
    margin-top: 56px;
  }
}

@media screen and (max-width: 460px) {
  .about-me {
    padding: 70px 14px 0;
  }

  .about-me__section-title {
    margin: 0 0 27px;
    font-size: 18px;
    line-height: 22px;
  }

  .about-me__container {
    flex-direction: column-reverse;
  }

  .about-me__img-container {
    width: calc(100vw - 28px);
    display: flex;
    justify-content: center;
  }

  .about-me__description-photo {
    width: 100%;
    max-width: 300px;
    min-height: 352px;
    margin-top: 52px;
    border-radius: 10px;
    object-fit: cover;
  }

  .about-me__description-name {
    margin-top: 39px;
    font-size: 30px;
    line-height: 36px;
  }

  .about-me__description-about {
    font-size: 11px;
    line-height: 16px;
    margin-top: 21px;
  }

  .about-me__description-text {
    margin: 20px 0 38px;
    font-size: 11px;
    line-height: 16px;
  }

  .about-me__description {
    padding-right: 0px;
  }
}
