.techs {
  padding: 100px 70px;
  background: #f5f5f5;
}

.techs__title {
  margin: 0 0 23px;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.04em;
}

.techs__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
  margin-bottom: 87px;
}

.techs__descripion-title {
  margin: 0;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
  text-align: center;
  letter-spacing: -0.04em;
}

.techs__description-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.04em;
  max-width: 460px;
}

.techs__badges {
  display: flex;
  justify-content: center;
  gap: 10px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.04em;
}

.techs__badge {
  width: 90px;
  padding: 21px 0;
  text-align: center;
  background: #e8e8e8;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .techs {
    padding: 91px 50px;
  }

  .techs__description {
    margin-top: 79px;
    margin-bottom: 70px;
  }

  .techs__description-text {
    font-size: 12px;
    line-height: 18px;
  }

  .techs__badge {
    width: 84px;
  }

  .techs__badges {
    font-size: 12px;
    line-height: 15px;
  }
}

@media screen and (max-width: 660px) {
  .techs__badges {
    font-size: 12px;
    line-height: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(84px, 84px));
    margin: 0 auto;
  }
}

@media screen and (max-width: 460px) {
  .techs {
    padding: 71px 18px;
    background: #FAFAFA;
  }

  .techs__title {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 27px;
  }

  .techs__descripion-title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 12px;
  }

  .techs__description {
    margin-top: 60px;
    margin-bottom: 39px;
  }

  .techs__description-text {
    font-size: 11px;
    line-height: 16px;
  }
}

@media screen and (max-width: 320px) {
  .techs__badges {
    grid-template-columns: min-content min-content;
  }
}
