.filter-checkbox {
  display: flex;
  align-items: center;
  border-left: 1px solid #cbcbcb;
  min-height: 40px;
  padding-left: 29px;
}

.filter-checkbox__input {
  height: 0;
  width: 0;
}

.filter-checkbox__label {
  cursor: pointer;
  text-indent: -9999px;
  width: 34px;
  height: 14px;
  margin: 3px 1px;
  background: #EBEBEB;
  display: block;
  border-radius: 20px;
  position: relative;
}

.filter-checkbox__label:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 3px;
  width: 10px;
  height: 10px;
  background: #f5f5f5;
  border-radius: 90px;
  transition: 0.2s;
}

.filter-checkbox__input:checked + .filter-checkbox__label {
  background: #2BE080;
}

.filter-checkbox__input:checked + label:after {
  background: #FFFFFF;
}

.filter-checkbox__input:checked + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.filter-checkbox__text {
  padding-left: 14px;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

@media screen and (max-width: 768px) {
  .filter-checkbox {
    padding-left: 19px;
  }

  .filter-checkbox__text {
    padding-left: 15px;
  }
}

@media screen and (max-width: 600px) {
  .filter-checkbox {
    position: absolute;
    top: 107px;
    left: 50%;
    transform: translateX(-50%);
    border-left: none;
    padding-left: 0;
  }

  .filter-checkbox__text {
    padding-left: 14px;
    font-size: 11px;
  }
}
