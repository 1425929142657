.portfolio {
  padding: 100px 70px 106px;
  background: #FAFAFA;
}

.portfolio__section-title {
  margin: 0 0 30px;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #A0A0A0;
}

.portfolio__links {
  display: flex;
  flex-direction: column;
}

.portfolio__link {
  padding: 19px 6px 19px 0;
  font-weight: 400;
  font-size: 30px;
  line-height: 50px;
  letter-spacing: -0.04em;
  color: #000000;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
}

.portfolio__link:hover {
  opacity: 0.7;
}

.portfolio__link:last-child {
  border-bottom: none;
}

@media screen and (max-width: 768px) {
  .portfolio {
    padding: 86px 50px 69px;
  }

  .portfolio__link {
    padding-right: 5px;
    font-size: 28px;
  }

  .portfolio__link-img {
    padding-top: 4px;
  }
}

@media screen and (max-width: 460px) {
  .portfolio {
    padding: 70px 14px 69px;
  }

  .portfolio__link {
    font-size: 18px;
    padding: 0 4px 9px 0;
    margin-bottom: 9px;
  }

  .portfolio__link-img {
    padding-top: 0;
  }

  .portfolio__section-title {
    margin: 0 0 28px;
    font-size: 14px;
  }

  .portfolio__link-img {
    width: 11px;
  }
}
