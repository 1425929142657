.moviescard {
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 270px;
  max-height: 218px;
  overflow: hidden;

  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background: #FFFFFF;
}

.moviescard__image {
  min-width: 100%;
  max-height: 151px;
  vertical-align: bottom;
  transition: all 0.16s linear;
  object-fit: cover;
}

.moviescard__image:hover {
  cursor: pointer;
  transform: scale(1.02);
  transform-origin: 50% 100%;
}

.moviescard__details {
  padding: 14px 12px;
  position: relative;
}

.moviescard__name {
  margin: 0 0 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.moviescard__duration {
  margin: 0;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #a0a0a0;
}

.moviescard__like-btn {
  background: no-repeat center/contain url('../../images/icon-no-liked-1.svg');
  padding: 0;
  margin: 0;
  width: 16px;
  height: 16px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 12px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.09);
}

.moviescard__like-btn_liked {
  background: no-repeat center/contain url('../../images/icon-liked-1.svg');
}

.moviescard__delete-btn {
  background: no-repeat center/contain url('../../images/icon-close.svg');
  padding: 0;
  margin: 0;
  width: 16px;
  height: 16px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 12px;
}

.moviescard__container {
  min-width: 100%;
  max-height: 151px;
}

.moviescard__container:hover .moviescard__delete-btn {
  display: block;
}

@media screen and (max-width: 920px) {
  .moviescard {
    max-width: 339px;
    max-height: 257px;
  }

  .moviescard__image {
    max-height: 190px;
  }
}

@media screen and (max-width: 460px) {
  .moviescard {
    max-width: 300px;
    max-height: 325px;
  }

  .moviescard__image {
    height: 168px;
  }
}
