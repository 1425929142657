.movies {
  padding: 0 70px;
  min-height: calc(100vh - 268px);
  display: flex;
  flex-direction: column;
  background: #FAFAFA;
}

.movies__not-found {
  text-align: center;
  margin: 80px 0 0;
}

.movies__api-error {
  text-align: center;
  margin: 60px auto 0;
  max-width: 600px;
}

.movies__more-btn {
  width: 320px;
  height: 36px;
  margin: 80px auto 80px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  border: none;
  background-color: #F4F4F4;
  border-radius: 6px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.1s linear;
}

.movies__more-btn:hover {
  opacity: 0.8;
}

@media screen and (max-width: 920px) {
  .movies {
    padding: 0 30px;
  }

  .movies__more-btn {
    margin-bottom: 76px;
  }
}

@media screen and (max-width: 460px) {
  .movies {
    padding: 0 10px;
  }

  .movies__more-btn {
    width: 240px;
    margin: 80px auto 109px;
  }
}
