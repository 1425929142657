.search {
  padding: 70px 0 69px;
  border-bottom: 1px solid #e8e8e8;
}

.search__form {
  display: flex;
  align-items: center;
  padding: 0 30px;
  border-radius: 9px;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.08);
  min-height: 72px;
}

.search__form-input-field {
  position: relative;
  width: 100%;
}

.search__form-input {
  border: none;
  width: 93%;
  display: block;
  padding: 9px 4px 9px 33px;
  margin-right: 10px;
  background: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.79268 8.2638C6.36075 9.69572 4.03915 9.69572 2.60723 8.2638C1.1753 6.83188 1.1753 4.51027 2.60723 3.07835C4.03915 1.64643 6.36075 1.64643 7.79268 3.07835C9.2246 4.51027 9.2246 6.83188 7.79268 8.2638ZM8.23306 9.64676C6.27283 11.1462 3.45724 10.9994 1.66442 9.20661C-0.288204 7.25399 -0.288204 4.08816 1.66442 2.13554C3.61704 0.182918 6.78286 0.182918 8.73549 2.13554C10.5282 3.92829 10.675 6.74371 9.17584 8.70392L12.7425 12.2706L11.7997 13.2134L8.23306 9.64676Z' fill='%23959595'/%3E%3C/svg%3E%0A")
    no-repeat 0 10px;
}

.search__form-input::placeholder {
  font-size: 18px;
  line-height: 20px;
}

.search__form-button {
  all: unset;
  height: 34px;
  margin-right: 19px;
  cursor: pointer;
}

.search__form-button:disabled {
  filter: grayscale(1);
}

.search__form-reset-button {
  position: absolute;
  top: 7px;
  right: 18px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #555;
}

.search__form-button:hover {
  opacity: 0.8;
}

.search__form-button:focus {
  outline: 2px solid black;
  border-radius: 50px;
}

.search__form-input-error {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  position: absolute;
  left: 33px;
  top: 36px;
  max-width: 410px;
  color: #ee3465;
}

@media screen and (max-width: 768px) {
  .search {
    padding: 80px 0 109px;
  }

  .search__form {
    padding: 0 21px;
  }

  .search__form-button {
    margin-right: 19px;
  }

  .search__form-input {
    padding: 8px 4px 7px 42px;
    margin-right: 10px;
    background: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.79268 8.2638C6.36075 9.69572 4.03915 9.69572 2.60723 8.2638C1.1753 6.83188 1.1753 4.51027 2.60723 3.07835C4.03915 1.64643 6.36075 1.64643 7.79268 3.07835C9.2246 4.51027 9.2246 6.83188 7.79268 8.2638ZM8.23306 9.64676C6.27283 11.1462 3.45724 10.9994 1.66442 9.20661C-0.288204 7.25399 -0.288204 4.08816 1.66442 2.13554C3.61704 0.182918 6.78286 0.182918 8.73549 2.13554C10.5282 3.92829 10.675 6.74371 9.17584 8.70392L12.7425 12.2706L11.7997 13.2134L8.23306 9.64676Z' fill='%23959595'/%3E%3C/svg%3E%0A")
      no-repeat 9px 10px;
  }
}

@media screen and (max-width: 600px) {
  .search__form {
    position: relative;
    padding: 0 19px;
  }

  .search__form-input::placeholder {
    font-size: 14px;
    line-height: 20px;
  }

  .search__form-input {
    padding: 6px 4px 10px 0;
    margin-right: 10px;
    background: none;
  }

  .search__form-button {
    margin-right: 0;
  }

  .search__form-input-error {
    left: 0px;
  }
}
